import { s as n } from "../../../index-CARpW-rC.js";
import s from "../../../assets/styles/theme.js";
import '../../../assets/Legend.css';const l = () => ({
  hasError: e
}) => e ? s.colors.complimentary.red : s.colors.grayscale.veryDark, o = () => ({
  isRequired: e
}) => e ? "inline-block" : "none", a = /* @__PURE__ */ n("legend")({
  name: "StyledLegend",
  class: "s1xelnns",
  propsAsIs: !1,
  vars: {
    "s1xelnns-0": [l()],
    "s1xelnns-1": [o()]
  }
});
export {
  a as StyledLegend
};
