import { jsx as t, jsxs as n } from "react/jsx-runtime";
import a from "../../molecules/FileLoader/FileLoader.js";
import { StyledGenerateCvButton as i } from "./GenerateCvButton.styles.js";
import { buttonDataTest as l, buttonTextSmall as u, buttonTextMain as m } from "./GenerateCvButton.constants.js";
function c({ handleClick: e, isLoading: r, name: o }) {
  return r ? /* @__PURE__ */ t(a, { id: `${o}Loader`, value: 100 }) : /* @__PURE__ */ n(i, { "data-test": l, onClick: e, type: "button", children: [
    /* @__PURE__ */ t("small", { children: u }),
    /* @__PURE__ */ t("span", { children: m })
  ] });
}
export {
  c as default
};
