const i = {
  size: 62,
  iconSize: 62
}, a = {
  size: 64,
  iconSize: 56
}, o = {
  size: 64,
  iconSize: 56
}, t = {
  size: 96,
  iconSize: 72
}, n = "picture-companyLogo";
export {
  a as compactVariant,
  n as dataTestId,
  t as defaultVariant,
  o as shadowVariant,
  i as squareVariant
};
