import { s as e } from "./index-CARpW-rC.js";
import './assets/JobOfferBox.css';var a = /* @__PURE__ */ ((s) => (s.Top = "Top", s.Bottom = "Bottom", s))(a || {}), r = /* @__PURE__ */ ((s) => (s.Default = "Default", s.Compact = "Compact", s.WithBackground = "WithBackground", s))(r || {});
const l = /* @__PURE__ */ e("div")({
  name: "StyledJobOffersWrapper",
  class: "s12meelm",
  propsAsIs: !1
}), t = () => ({
  subheaderPosition: s
}) => {
  switch (s) {
    case a.Top:
      return "column-reverse";
    default:
      return "column";
  }
}, p = /* @__PURE__ */ e("div")({
  name: "StyledDescriptionBox",
  class: "srrjx06",
  propsAsIs: !1,
  vars: {
    "srrjx06-0": [t()]
  }
}), d = /* @__PURE__ */ e("h2")({
  name: "StyledHeader",
  class: "s1dd3a72",
  propsAsIs: !1
}), c = /* @__PURE__ */ e("span")({
  name: "StyledSubheader",
  class: "s1s5p6ss",
  propsAsIs: !1
});
export {
  r as J,
  a as S,
  l as a,
  p as b,
  d as c,
  c as d
};
