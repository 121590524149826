const i = {
  portraitPhone: "@media (min-width: 360px)",
  landscapePhone: "@media (min-width: 480px)",
  phablet: "@media (min-width: 600px)",
  portraitTablet: "@media (min-width: 768px)",
  landscapeTablet: "@media (min-width: 840px)",
  laptop: "@media (min-width: 1024px)",
  desktop: "@media (min-width: 1280px)",
  hdDesktop: "@media (min-width: 1366px)",
  fhdDesktop: "@media (min-width: 1920px)"
};
export {
  i as default
};
