import { jsx as l } from "react/jsx-runtime";
import { StyledSkeleton as m } from "./Skeleton.styles.js";
function u({ width: t, height: e, withoutAnimation: o, backgroundColor: r, borderRadius: n, className: f }) {
  return /* @__PURE__ */ l(
    m,
    {
      height: e,
      width: t,
      withoutAnimation: o,
      backgroundColor: r,
      borderRadius: n,
      className: f
    }
  );
}
export {
  u as default
};
