import { jsx as s } from "react/jsx-runtime";
import { s as r } from "../../../index-CARpW-rC.js";
import '../../../assets/FormPanel.css';const t = /* @__PURE__ */ r("div")({
  name: "FormBox",
  class: "fz4d9l0",
  propsAsIs: !1
});
function m({
  children: o
}) {
  return /* @__PURE__ */ s(t, {
    children: o
  });
}
export {
  m as default
};
