import { jsxs as c, jsx as a } from "react/jsx-runtime";
import { memo as B } from "react";
import g from "../../atoms/CompanyLogo/CompanyLogo.js";
import { J as s, a as y, b as J, c as O, d as C, S as D } from "../../../JobOfferBox.styles-C9PfCk8r.js";
const j = 76;
function M({
  children: f,
  headerText: t,
  headerDataTest: l,
  subheaderText: n,
  subheaderDataTest: d,
  subheaderPosition: m = D.Bottom,
  maxCharacters: r = j,
  dataTest: p,
  dataTestLogo: u,
  dataTestDescription: e,
  variant: o = s.Default
}) {
  const i = (t == null ? void 0 : t.length) > r, S = i ? t == null ? void 0 : t.slice(0, r) : "", b = (/* @__PURE__ */ new Map([
    [s.Compact, "Compact"],
    [s.WithBackground, "Square"]
  ])).get(o) ?? "Default";
  return /* @__PURE__ */ c(y, { "data-test": p, "data-variant": o, children: [
    /* @__PURE__ */ a(g, { dataTest: u, variant: b, children: f }),
    /* @__PURE__ */ c(J, { "data-test": e, subheaderPosition: m, children: [
      /* @__PURE__ */ a(O, { "data-test": l, "data-variant": o, children: i ? `${S}...` : t }),
      n && /* @__PURE__ */ a(C, { "data-test": d, "data-variant": o, children: n })
    ] })
  ] });
}
const q = B(M);
export {
  q as default
};
