import { s } from "../../../index-CARpW-rC.js";
import n from "../../../assets/styles/theme.js";
import '../../../assets/Skeleton.css';const e = () => ({
  backgroundColor: p
}) => p ?? n.colors.primary.background, r = () => ({
  borderRadius: p
}) => p ?? "4px", o = () => ({
  width: p
}) => p ? `min(100%,${p})` : "100%", t = () => ({
  height: p
}) => p ?? "100%", x = () => ({
  withoutAnimation: p
}) => p ? "paused" : "running", l = /* @__PURE__ */ s("span")({
  name: "StyledSkeleton",
  class: "sn0rpxp",
  propsAsIs: !1,
  vars: {
    "sn0rpxp-0": [e()],
    "sn0rpxp-1": [r()],
    "sn0rpxp-2": [o()],
    "sn0rpxp-3": [t()],
    "sn0rpxp-4": [x()]
  }
});
export {
  l as StyledSkeleton
};
