import { s as o } from "../../../index-CARpW-rC.js";
import '../../../assets/CompanyLogo.css';const p = () => ({
  width: s
}) => s, e = () => ({
  height: s
}) => s, a = /* @__PURE__ */ o("picture")({
  name: "StyledCompanyLogo",
  class: "s1336chy",
  propsAsIs: !1,
  vars: {
    "s1336chy-0": [p(), "px"],
    "s1336chy-1": [e(), "px"]
  }
});
export {
  a as StyledCompanyLogo
};
