import { jsx as t } from "react/jsx-runtime";
import s from "../Icon/Icon.js";
import { StyledCompanyLogo as d } from "./CompanyLogo.styles.js";
import { squareVariant as z, compactVariant as i, shadowVariant as a, defaultVariant as n, dataTestId as S } from "./CompanyLogo.constants.js";
function g({
  dataTest: r = S,
  children: c,
  variant: o = "Default",
  className: m
}) {
  const e = (/* @__PURE__ */ new Map([
    ["Square", z.size],
    ["Compact", i.size],
    ["Shadow", a.size]
  ])).get(o) ?? n.size, p = (/* @__PURE__ */ new Map([
    ["Square", a.iconSize],
    ["Compact", i.iconSize],
    ["Shadow", a.iconSize]
  ])).get(o) ?? n.iconSize;
  return /* @__PURE__ */ t(d, { "data-test": r, "data-variant": o, height: e, width: e, className: m, children: c ?? /* @__PURE__ */ t(s, { iconType: "CompanyLogoPlaceholder", size: p }) });
}
export {
  g as default
};
