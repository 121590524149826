import { jsx as r } from "react/jsx-runtime";
import { s as e } from "../../../index-CARpW-rC.js";
import '../../../assets/Background.css';const a = () => ({
  imageUrl: s
}) => `url(${s})`, t = /* @__PURE__ */ e("div")({
  name: "StyledBackground",
  class: "su1b6ze",
  propsAsIs: !1,
  vars: {
    "su1b6ze-0": [a()]
  }
});
function u({
  imageUrl: s
}) {
  return /* @__PURE__ */ r(t, {
    "aria-hidden": "true",
    imageUrl: s
  });
}
export {
  u as default
};
