import { s } from "./index-CARpW-rC.js";
import './assets/Separator.css';var r = /* @__PURE__ */ ((e) => (e.Empty = "Empty", e.Small = "Small", e.Medium = "Medium", e.Large = "Large", e))(r || {});
const t = () => ({
  space: e
}) => {
  switch (e) {
    case r.Empty:
      return "0";
    case r.Small:
      return "12px 0";
    case r.Large:
      return "32px 0";
    default:
      return "24px 0";
  }
}, l = /* @__PURE__ */ s("hr")({
  name: "StyledHr",
  class: "s1eibrk5",
  propsAsIs: !1,
  vars: {
    "s1eibrk5-0": [t()]
  }
});
export {
  r as H,
  l as S
};
