import { jsx as e } from "react/jsx-runtime";
import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/HeroBanner.css';const t = /* @__PURE__ */ s("div")({
  name: "BannerWrapper",
  class: "b1ori9vv",
  propsAsIs: !1
});
function p({
  children: r,
  dataTest: a
}) {
  return /* @__PURE__ */ e(t, {
    "data-test": a,
    children: r
  });
}
export {
  p as default
};
