import { jsxs as I, jsx as a } from "react/jsx-runtime";
import { useMemo as x } from "react";
import { s as p } from "../../../index-CARpW-rC.js";
import t from "../../../assets/styles/theme.js";
import m, { HelperTextType as u } from "../../atoms/HelperText/HelperText.js";
import { M as c } from "../../../MultilineInput.models-BigbyqP2.js";
import '../../../assets/CompactMultilineInput.css';const M = () => ({
  collapsed: e
}) => e ? "absolute" : "relative", v = () => ({
  collapsed: e
}) => e ? "hidden" : "initial", F = () => ({
  collapsed: e
}) => e ? 0 : "auto", L = () => ({
  collapsed: e
}) => e ? 0 : 1, $ = () => ({
  readOnly: e
}) => e ? t.colors.grayscale.disabledLight : t.colors.grayscale.white, k = /* @__PURE__ */ p("div")({
  name: "StyledFieldWrapper",
  class: "s1h979ep",
  propsAsIs: !1,
  vars: {
    "s1h979ep-0": [M()],
    "s1h979ep-1": [v()],
    "s1h979ep-2": [F()],
    "s1h979ep-3": [L()],
    "s1h979ep-4": [$()]
  }
}), w = () => ({
  hasError: e
}) => e ? t.colors.complimentary.red : t.colors.grayscale.dark, A = () => ({
  sizeVariant: e
}) => {
  switch (e) {
    case c.S:
      return "35px 16px 10px";
    default:
      return "43px 16px 17px";
  }
}, H = () => ({
  hasError: e
}) => e ? t.colors.complimentary.red : t.colors.primary.default, j = /* @__PURE__ */ p("textarea")({
  name: "StyledTextarea",
  class: "sqm806h",
  propsAsIs: !1,
  vars: {
    "sqm806h-0": [w()],
    "sqm806h-1": [A()],
    "sqm806h-2": [H()]
  }
}), z = () => ({
  sizeVariant: e
}) => {
  switch (e) {
    case c.S:
      return "10px";
    default:
      return "18px";
  }
}, B = () => ({
  hasError: e
}) => e ? t.colors.complimentary.red : t.colors.grayscale.dark, D = () => ({
  hasError: e
}) => e ? t.colors.complimentary.red : t.colors.primary.default, E = () => ({
  isRequired: e
}) => e ? "inline-block" : "none", O = /* @__PURE__ */ p("label")({
  name: "StyledFloatLabel",
  class: "s1aeunqa",
  propsAsIs: !1,
  vars: {
    "s1aeunqa-0": [z()],
    "s1aeunqa-1": [B()],
    "s1aeunqa-2": [D()],
    "s1aeunqa-3": [E()]
  }
}), R = -1, V = 8, W = 500, C = "text-errorMessage", N = "text-hintMessage";
function X({
  dataTest: e,
  name: s,
  maxLength: h = W,
  label: f,
  value: y,
  readonly: r = !1,
  required: _ = !1,
  error: o = "",
  isHidden: i = !1,
  hint: l = "",
  rows: q = V,
  size: d = c.L,
  onBlur: b,
  onChange: S,
  isInlineField: T
}) {
  const n = x(() => !!o, [o]), g = x(() => !!l, [l]);
  return /* @__PURE__ */ I(k, {
    collapsed: i,
    "data-inline-field": T,
    "data-test": `field-${s}${i ? "-hidden" : ""}${e ? ` ${e}` : ""}`,
    readOnly: r,
    children: [/* @__PURE__ */ a(j, {
      "data-test": `textarea-${s}`,
      hasError: n,
      id: s,
      maxLength: h,
      name: s,
      onBlur: b,
      onChange: S,
      placeholder: " ",
      readOnly: r,
      rows: q,
      sizeVariant: d,
      tabIndex: r ? R : 0,
      value: y
    }), /* @__PURE__ */ a(O, {
      hasError: n,
      htmlFor: s,
      isRequired: _,
      sizeVariant: d,
      children: f
    }), n && /* @__PURE__ */ a(m, {
      dataTest: C,
      text: o,
      type: u.Error
    }), g && /* @__PURE__ */ a(m, {
      dataTest: N,
      text: l,
      type: u.Hint
    })]
  });
}
export {
  X as default
};
